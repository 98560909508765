import { useState } from 'react';

export default function InternalImageRendering() {
  const [imageUrls, setImageUrls] = useState('');
  const [renderedImages, setRenderedImages] = useState<string[]>([]);

  const handleRender = () => {
    const urlList = imageUrls.split('\n').filter((url) => url.trim() !== '');
    setRenderedImages(urlList);
  };

  return (
    <div className="flex flex-col items-start mx-2">
      <div className="my-2">Input image URLs</div>
      <textarea
        value={imageUrls}
        onChange={(e) => setImageUrls(e.target.value)}
        placeholder="Enter image URLs (one per line)"
        rows={5}
        cols={50}
      />
      <button
        className="p-2 my-2 border border-gray-500 border-solid"
        onClick={handleRender}
      >
        Render
      </button>
      <div className="grid grid-cols-5 ">
        {renderedImages.map((url, index) => (
          <div key={index}>
            <img
              src={url}
              alt={`${index + 1}`}
              onError={(e) => {
                console.error('Error rendering image:', e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
