import { AngleDoubleLeft, AngleDoubleRight, ExitFilled } from '@/icons';
import {
  ApiOutlined,
  ControlOutlined,
  GroupOutlined,
  LineChartOutlined,
  PartitionOutlined,
  PlayCircleOutlined,
  ProfileOutlined,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Tooltip } from 'antd';
import { compact } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import DashboardMenuButton from './components/DashboardMenuButton';

import LogoAndWordmarkPurple from '../../images/LogoAndWordmarkPurple.png';
import { RequireAuth } from '../../routing/auth';
import AccountSettings from '../settings/AccountSettings';
import ApiAuthenticationSettings from '../settings/ApiAuthenticationSettings';
import BillingSettings from '../settings/BillingSettings';
import ManageUsers from '../settings/ManageUsers';
import ActionForm from './actions/ActionForm';
import ActionsDashboard from './actions/ActionsDashboard';
import LocationBankForm from './banks/location/LocationBankForm';
import TextBankForm from './banks/text/TextBankForm';
import BulkActioningDashboard from './bulk_actioning/BulkActioningDashboard';
import IntegrationConfigForm from './integrations/IntegrationConfigForm';
import IntegrationsDashboard from './integrations/IntegrationsDashboard';
import InvestigationDashboard from './investigation/InvestigationDashboard';
import ItemTypeForm from './item_types/ItemTypeForm';
import ItemTypesDashboard from './item_types/ItemTypesDashboard';
import ManualReviewJobReview from './mrt/manual_review_job/ManualReviewJobReview';
import ManualReviewAnalyticsDashboard from './mrt/ManualReviewAnalyticsDashboard';
import ManualReviewQueueForm from './mrt/ManualReviewQueueForm';
import ManualReviewQueueJobsPreview from './mrt/ManualReviewQueueJobsPreview';
import ManualReviewQueuesDashboard from './mrt/ManualReviewQueuesDashboard';
import ManualReviewRecentDecisions from './mrt/ManualReviewRecentDecisions';
import ManualReviewSafetySettings from './mrt/ManualReviewSafetySettings';
import ManualReviewQueueRoutingDashboard from './mrt/queue_routing/ManualReviewQueueRoutingDashboard';
import NcmecReportsDashboard from './ncmec/NcmecReportsDashboard';
import PoliciesDashboard from './policies/PoliciesDashboard';
import ReportingRulesDashboard from './rules/dashboard/ReportingRulesDashboard';
import RulesDashboard from './rules/dashboard/RulesDashboard';
import ReportingRuleInfo from './rules/info/ReportingRuleInfo';
import RuleInfo from './rules/info/RuleInfo';
import ReportingRuleForm from './rules/rule_form/ReportingRuleForm';
import RuleForm from './rules/rule_form/RuleForm';

import './Dashboard.css';

import ErrorBoundary from '../../components/common/ErrorBoundary';

import {
  GQLUserPermission,
  namedOperations,
  useGQLDashboardOrgQuery,
  useGQLLogoutMutation,
} from '../../graphql/generated';
import OrgSafetySettings from '../settings/OrgSafetySettings';
import MatchingBanksDashboard from './banks/MatchingBanksDashboard';
import ManualReviewAppealSettings from './mrt/ManualReviewAppealSettings';
import UserStrikesDashboard from './userStrikes/userStrikesDashboard';

type MenuItem = {
  title: string;
  urlPath: string;
  icon?: React.ReactNode;
  requiredPermissions: GQLUserPermission[];
  // Only allow one level of subItems
  subItems?: Omit<MenuItem, 'subItems'>[];
};

gql`
  query DashboardOrg {
    myOrg {
      id
      name
      hasMrtAccess
      hasReportingRulesEnabled
      hasNCMECReportingEnabled
      hasAppealsEnabled
    }
    me {
      id
      permissions
      email
    }
  }

  mutation Logout {
    logout
  }
`;

export const DashboardRoutes = {
  path: 'dashboard',
  element: (
    <RequireAuth>
      <Dashboard />
    </RequireAuth>
  ),
  children: [
    // Proactive Rules
    {
      path: '',
      element: <DashboardRoot />,
    },
    {
      path: 'rules',
      element: <Navigate replace to="proactive" />,
    },
    {
      path: 'rules/proactive',
      element: (
        <ErrorBoundary buttonLinkPath="/" buttonTitle="Home">
          <RulesDashboard />
        </ErrorBoundary>
      ),
    },
    {
      path: 'rules/proactive/info/:id',
      element: <RuleInfo />,
    },
    {
      path: 'rules/proactive/form/:id?',
      element: <RuleForm />,
    },

    // Reporting Rules
    {
      path: 'rules/report',
      element: (
        <ErrorBoundary buttonLinkPath="/" buttonTitle="Home">
          <ReportingRulesDashboard />
        </ErrorBoundary>
      ),
    },
    {
      path: 'rules/report/info/:id',
      element: <ReportingRuleInfo />,
    },
    {
      path: 'rules/report/form/:id?',
      element: <ReportingRuleForm />,
    },

    // Actions
    { path: 'actions', element: <ActionsDashboard /> },
    {
      path: 'actions/form/:id?',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/dashboard/actions`}
          buttonTitle="Back to Actions Dashboard"
        >
          <ActionForm />
        </ErrorBoundary>
      ),
    },

    // Item Types
    { path: 'item_types', element: <ItemTypesDashboard /> },
    {
      path: 'item_types/form/:id?',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/`}
          buttonTitle="Back to Item Types Dashboard"
        >
          <ItemTypeForm />
        </ErrorBoundary>
      ),
    },
    {
      path: 'content_types',
      element: <Navigate replace to="../item_types" />,
    },

    // Manual Review Tool (MRT)
    {
      path: 'manual_review',
      element: <Navigate replace to="queues" />,
    },
    {
      path: 'manual_review/queues',
      element: <ManualReviewQueuesDashboard />,
    },
    {
      path: 'manual_review/queues/form/:id?',
      element: <ManualReviewQueueForm />,
    },
    {
      path: 'manual_review/queues/review/:queueId/:jobId?/:lockToken?',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/dashboard/manual_review/queues`}
          buttonTitle="Back to All Queues"
        >
          <ManualReviewJobReview />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/queues/jobs/:queueId',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/dashboard/manual_review/queues`}
          buttonTitle="Back to All Queues"
        >
          <ManualReviewQueueJobsPreview />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/ncmec_reports',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <NcmecReportsDashboard />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/routing',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <ManualReviewQueueRoutingDashboard />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/recent',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <ManualReviewRecentDecisions />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/safety',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <ManualReviewSafetySettings />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/analytics',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <ManualReviewAnalyticsDashboard />
        </ErrorBoundary>
      ),
    },
    {
      path: 'manual_review/appeal_settings',
      element: (
        <ErrorBoundary
          buttonTitle="Back to Manual Review Queues"
          buttonLinkPath={`/`}
        >
          <ManualReviewAppealSettings />
        </ErrorBoundary>
      ),
    },

    // Bulk Actioning Tool
    { path: 'bulk-actioning', element: <BulkActioningDashboard /> },

    // Investigation Tool
    { path: 'investigation', element: <InvestigationDashboard /> },

    // Policies
    { path: 'policies', element: <PoliciesDashboard /> },

    // Policies
    // TODO: uncomment this when final UI is finished
    { path: 'userStrikes', element: <UserStrikesDashboard /> },

    // Integrations
    { path: 'integrations', element: <IntegrationsDashboard /> },
    {
      path: 'integrations/:name',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/`}
          buttonTitle="Back to Integrations Dashboard"
        >
          <IntegrationConfigForm />
        </ErrorBoundary>
      ),
    },

    // Matching Banks
    { path: 'banks', element: <MatchingBanksDashboard /> },
    {
      path: 'banks/text',
      element: <Navigate replace to="/dashboard/banks?kind=TEXT" />,
    },
    {
      path: 'banks/location',
      element: <Navigate replace to="/dashboard/banks?kind=LOCATION" />,
    },
    {
      path: 'banks/form/text/:id?',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/`}
          buttonTitle="Back to Text Banks Dashboard"
        >
          <TextBankForm />
        </ErrorBoundary>
      ),
    },
    {
      path: 'banks/form/location/:id?',
      element: (
        <ErrorBoundary
          buttonLinkPath={`/`}
          buttonTitle="Back to Location Banks Dashboard"
        >
          <LocationBankForm />
        </ErrorBoundary>
      ),
    },

    // Settings
    { path: 'settings', element: <Navigate replace to="account" /> },
    {
      path: 'settings/account',
      element: <AccountSettings />,
    },
    {
      path: 'settings/api_auth',
      element: <ApiAuthenticationSettings />,
    },
    {
      path: 'settings/org_safety_settings',
      element: <OrgSafetySettings />,
    },
    {
      path: 'settings/billing',
      element: <BillingSettings />,
    },
    {
      path: 'settings/users',
      element: <ManageUsers />,
    },
  ],
};

/**
 * Org Dashboard screen
 */
export default function Dashboard() {
  const { pathname } = useLocation();
  const { loading, error, data } = useGQLDashboardOrgQuery();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(false);

  const [logout, { client }] = useGQLLogoutMutation({
    onError: () => {},
    onCompleted: (_data) => {
      client.resetStore().then(() => navigate('/'));
    },
    refetchQueries: [namedOperations.Query.PermissionGatedRouteLoggedInUser],
  });

  const permissions = data?.me?.permissions;

  const rulesItem = {
    title: 'Rules',
    urlPath: 'rules',
    icon: <ControlOutlined />,
    requiredPermissions: [GQLUserPermission.ViewRulesDashboard],
  };

  const mrtOnlyOrg = isMrtOnlyOrg(data?.myOrg?.id ?? '');
  /**
   * All left sidebar menu items are listed here
   */
  const menuItems = compact([
    mrtOnlyOrg
      ? {
          title: 'Overview',
          urlPath: 'manual_review/analytics',
          icon: <LineChartOutlined />,
          requiredPermissions: [GQLUserPermission.ViewMrtData],
        }
      : data?.myOrg?.hasReportingRulesEnabled
      ? {
          ...rulesItem,
          subItems: compact([
            {
              title: 'Proactive Rules',
              urlPath: 'proactive',
              requiredPermissions: [],
            },
            data?.myOrg?.hasMrtAccess
              ? {
                  title: 'Report Rules',
                  urlPath: 'report',
                  requiredPermissions: [GQLUserPermission.ViewMrt],
                }
              : null,
          ]),
        }
      : rulesItem,
    data?.myOrg?.hasMrtAccess
      ? {
          title: 'Manual Review',
          urlPath: 'manual_review',
          icon: <SolutionOutlined />,
          requiredPermissions: [GQLUserPermission.ViewMrt],
          subItems: compact([
            {
              title: 'Queues',
              urlPath: 'queues',
              requiredPermissions: [],
            },
            {
              title: 'Routing',
              urlPath: 'routing',
              requiredPermissions: [GQLUserPermission.EditMrtQueues],
            },
            mrtOnlyOrg
              ? null
              : {
                  title: 'Analytics',
                  urlPath: 'analytics',
                  requiredPermissions: [GQLUserPermission.ViewMrtData],
                },
            {
              title: 'Recent Decisions',
              urlPath: 'recent',
              requiredPermissions: [GQLUserPermission.ViewMrtData],
            },
            ...(data?.myOrg?.hasNCMECReportingEnabled
              ? [
                  {
                    title: 'NCMEC Reports',
                    urlPath: 'ncmec_reports',
                    requiredPermissions: [
                      GQLUserPermission.ViewChildSafetyData,
                    ],
                  },
                ]
              : []),
            {
              title: 'Safety Settings',
              urlPath: 'safety',
              requiredPermissions: [],
            },
            ...(data?.myOrg?.hasAppealsEnabled
              ? [
                  {
                    title: 'Appeal Settings',
                    urlPath: 'appeal_settings',
                    requiredPermissions: [
                      GQLUserPermission.ViewMrt,
                      GQLUserPermission.ManageOrg,
                    ],
                  },
                ]
              : []),
          ]),
        }
      : null,
    {
      title: 'Item Types',
      urlPath: 'item_types',
      icon: <ReadOutlined />,
      requiredPermissions: [GQLUserPermission.ManageOrg],
    },
    {
      title: 'Actions',
      urlPath: 'actions',
      icon: <PlayCircleOutlined />,
      requiredPermissions: [GQLUserPermission.ManageOrg],
    },
    {
      title: 'Policies',
      urlPath: 'policies',
      icon: <ProfileOutlined />,
      requiredPermissions: [GQLUserPermission.ManageOrg],
    },
    //TODO: uncomment this when final UI is finished
    {
      title: 'User Strikes',
      urlPath: 'userStrikes',
      icon: <ProfileOutlined />,
      requiredPermissions: [GQLUserPermission.ManageOrg],
    },
    {
      title: 'Matching Banks',
      urlPath: 'banks',
      icon: <PartitionOutlined />,
      requiredPermissions: [GQLUserPermission.MutateNonLiveRules],
    },
    {
      title: 'Bulk Actioning',
      urlPath: 'bulk-actioning',
      icon: <GroupOutlined />,
      requiredPermissions: [GQLUserPermission.ManuallyActionContent],
    },
    {
      title: 'Investigation',
      urlPath: 'investigation',
      icon: <SearchOutlined />,
      requiredPermissions: [GQLUserPermission.ViewInvestigation],
    },
    {
      title: 'Integrations',
      urlPath: 'integrations',
      icon: <ApiOutlined />,
      requiredPermissions: [GQLUserPermission.ManageOrg],
    },
    {
      title: 'Settings',
      urlPath: 'settings',
      icon: <SettingOutlined />,
      requiredPermissions: [],
      subItems: [
        {
          title: 'Account',
          urlPath: 'account',
          requiredPermissions: [],
        },
        {
          title: 'API Authentication',
          urlPath: 'api_auth',
          requiredPermissions: [GQLUserPermission.ManageOrg],
        },
        {
          title: 'Employee Safety',
          urlPath: 'org_safety_settings',
          requiredPermissions: [GQLUserPermission.ManageOrg],
        },
        {
          title: 'Billing',
          urlPath: 'billing',
          requiredPermissions: [GQLUserPermission.ManageOrg],
        },
        {
          title: 'Users',
          urlPath: 'users',
          requiredPermissions: [GQLUserPermission.ManageOrg],
        },
      ],
    },
  ]) satisfies MenuItem[];

  // Whenever the URL 'pathname' changes, we want to update the
  // selectedMenuItem. For example, if someone loads the path
  // /dashboard/manual_review/queues, we want the "Queues" menu item under the
  // "Manual Review" menu item to be selected (i.e. highlighted). So we have to
  // traverse the menuItems object to figure out which item should be selected
  // based on the pathname.
  useEffect(() => {
    const pathParts = pathname.split('/');
    // The type assertion makes `items = item.subItems;` below work
    let items: MenuItem[] = menuItems;
    // Start at i = 2 because pathParts[0] will always be "" and pathParts[1]
    // will always be "dashboard" since the route is /dashboard/..., but we
    // check that pathParts.length >= 2 just in case.
    if (pathParts.length < 2) {
      return;
    }

    if (
      mrtOnlyOrg &&
      pathParts[2] === 'manual_review' &&
      pathParts[3] === 'analytics'
    ) {
      setSelectedMenuItem('Overview');
    }

    for (let i = 2; i < pathParts.length; i++) {
      const part = pathParts[i];
      const item = items.find((item) => item.urlPath === part);
      if (item == null) {
        return;
      }
      if (item.subItems) {
        // If the item has subItems, we should continue searching down that path
        items = item.subItems;
      } else {
        // If the item has no subItems, just return the item's title
        setSelectedMenuItem(item.title);
      }
    }
  }, [menuItems, pathname, mrtOnlyOrg]);

  /**
   * Determines whether the parent item passed in is indeed
   * a parent menu item of the descendant menu item title in the
   * second param.
   */
  const isDescendant = (
    parent: MenuItem,
    descendantTitle: string | null,
  ): Boolean => {
    if (descendantTitle == null) {
      return false;
    }
    return (
      parent.subItems != null &&
      parent.subItems.some(
        (subItem) =>
          subItem.title === descendantTitle ||
          isDescendant(subItem, descendantTitle),
      )
    );
  };

  const recursiveMenuItems = (
    item: MenuItem,
    level: number,
    prevUrlPath: string,
  ): ReactElement | null => {
    if (
      item.requiredPermissions.filter(
        (perm) => permissions?.includes(perm) ?? false,
      ).length < item.requiredPermissions.length
    ) {
      return null;
    }
    const subItems = item.subItems?.map((subItem, i) => (
      <React.Fragment key={i}>
        {recursiveMenuItems(subItem, level + 1, item.urlPath)}
      </React.Fragment>
    ));
    const isInSelectedPath =
      selectedMenuItem === item.title || isDescendant(item, selectedMenuItem);
    return (
      <div className="flex flex-col justify-start">
        <DashboardMenuButton
          title={item.title}
          url={
            prevUrlPath.length > 0
              ? `${prevUrlPath}/${item.urlPath}`
              : `${item.urlPath}`
          }
          selected={selectedMenuItem === item.title}
          onClick={() => {
            if (collapsed) {
              setCollapsed(false);
            }
            setSelectedMenuItem(item.title);
          }}
          level={level}
          icon={item.icon}
          collapsed={collapsed}
          // If this item is a top-level item and one of its children is
          // selected, highlight it
          highlighted={isInSelectedPath && level === 0}
        />
        {!collapsed && isInSelectedPath ? subItems : null}
      </div>
    );
  };

  const logoutButton = (
    <div
      className={`flex cursor-pointer w-min ${
        collapsed ? '' : 'justify-start items-center whitespace-nowrap'
      } h-min p-2 text-black hover:text-black/70 rounded border-none`}
      onClick={async () => logout()}
    >
      <ExitFilled className="w-5 h-5 fill-black" />
      {collapsed ? null : <div className="ml-2">Log Out</div>}
    </div>
  );

  const menu = (
    <div
      className={`relative flex flex-col justify-between bg-white ${
        collapsed ? '' : 'min-w-[220px]'
      }`}
    >
      <div className="flex flex-col p-4">
        <div className="flex items-center justify-between mb-6">
          {!collapsed && (
            <Link to="/" className="mt-1 ml-1 text-start">
              <img
                src={LogoAndWordmarkPurple}
                alt="Logo"
                width="110"
                height="29"
              />
            </Link>
          )}
          <div
            className="flex p-2 rounded cursor-pointer hover:bg-primary/10 h-min"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? (
              <AngleDoubleRight className="w-4 h-4 fill-black" />
            ) : (
              <AngleDoubleLeft className="w-4 h-4 fill-black" />
            )}
          </div>
        </div>
        {menuItems.map((item, i) => (
          <React.Fragment key={i}>
            {recursiveMenuItems(item, 0, '')}
          </React.Fragment>
        ))}
      </div>

      <div className="p-4 bg-[#f9f9f9]">
        {collapsed ? (
          <Tooltip title={'Log Out'} placement="right">
            {logoutButton}
          </Tooltip>
        ) : (
          logoutButton
        )}
      </div>
    </div>
  );

  if (error) {
    return <div />;
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <div className="flex w-full h-screen">
      <Helmet>
        <title>Home</title>
      </Helmet>
      {menu}
      <div className="w-px h-full bg-[#e5e7eb]" />
      <div className="flex justify-center w-full px-12 py-8 overflow-scroll scrollbar-hide">
        <ErrorBoundary
          buttonLinkPath="/dashboard"
          buttonTitle="Back to Rules Dashboard"
        >
          <div className="w-full max-w-[1280px]">
            <Outlet />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

function DashboardRoot() {
  const { loading, error, data } = useGQLDashboardOrgQuery();

  if (error) {
    return <Navigate replace to="../" />;
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  return isMrtOnlyOrg(data?.myOrg?.id ?? '') ? (
    <Navigate replace to="manual_review/analytics" />
  ) : data?.me?.permissions.includes(GQLUserPermission.ViewMrt) &&
    !data?.me.permissions.includes(GQLUserPermission.ViewRulesDashboard) ? (
    <Navigate replace to="manual_review/queues" />
  ) : (
    <Navigate replace to="rules/proactive" />
  );
}

function isMrtOnlyOrg(id: string) {
  return [
    '34bff959d00' /* test2@example.com */,
    'd259ca846a5' /* Notion */,
  ].includes(id);
}
