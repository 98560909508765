import { ChevronDown, ChevronUp } from '@/icons';
import React, { useEffect, useRef, useState } from 'react';

import CloseButton from '@/components/common/CloseButton';

import { GQLActionStatisticsGroupByColumns } from '../../../../../graphql/generated';

export type RuleDashboardInsightsGroupByColumns =
  GQLActionStatisticsGroupByColumns;

export function getDisplayNameForGroupByOption(
  option: RuleDashboardInsightsGroupByColumns,
) {
  switch (option) {
    case GQLActionStatisticsGroupByColumns.RuleId:
      return 'Rule';
    case GQLActionStatisticsGroupByColumns.PolicyId:
      return 'Policy';
    case GQLActionStatisticsGroupByColumns.ActionId:
      return 'Action';
    case GQLActionStatisticsGroupByColumns.ItemTypeId:
      return 'Item Type';
    case GQLActionStatisticsGroupByColumns.ActionSource:
      return 'Source';
  }
}

export default function RuleDashboardInsightsGroupBy(props: {
  selectedGroupBy: RuleDashboardInsightsGroupByColumns | undefined;
  setSelectedGroupBy: (
    groupBy: RuleDashboardInsightsGroupByColumns | undefined,
  ) => void;
}) {
  const { selectedGroupBy, setSelectedGroupBy } = props;
  const [groupByMenuVisible, setGroupByMenuVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setGroupByMenuVisible(false);
      }
    };

    if (groupByMenuVisible) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [groupByMenuVisible]);

  const groupByMenuButton = (option: RuleDashboardInsightsGroupByColumns) => {
    return (
      <div
        className={`px-2 py-0.5 m-1 text-start rounded cursor-pointer text-slate-500 font-medium ${
          selectedGroupBy === option
            ? 'bg-cove-lightblue'
            : 'bg-white hover:bg-cove-lightblue-hover'
        }`}
        key={option}
        onClick={() => {
          setSelectedGroupBy(option);
          setGroupByMenuVisible(false);
        }}
      >
        {getDisplayNameForGroupByOption(option)}
      </div>
    );
  };

  return (
    <div
      className="flex items-center text-start self-center"
      ref={componentRef}
    >
      <div className="font-semibold text-slate-500 pr-2 whitespace-nowrap">
        Group by
      </div>
      <div className="block relative float-left">
        <div
          onClick={() => setGroupByMenuVisible(!groupByMenuVisible)}
          className="cursor-pointer rounded border border-solid border-slate-200 hover:border-cove-blue py-1 px-2 flex items-center"
        >
          {selectedGroupBy ? (
            <div className="flex bg-gray-200 items-center py-0.5 px-2 font-medium text-slate-500 rounded whitespace-nowrap gap-1.5">
              {getDisplayNameForGroupByOption(selectedGroupBy)}
              <CloseButton
                onClose={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setSelectedGroupBy(undefined);
                }}
              />
            </div>
          ) : (
            <div className="text-slate-400 whitespace-nowrap">Select one</div>
          )}
          {/*
              We render both icons and toggle their visibility based on the groupByMenuVisible
              state instead of doing something like
              {groupByMenuVisible ? <ChevronUp /> : <ChevronDown />}
              because componentRef.current.contains doesn't work properly with that setup. It must
              be something about the component literally not being in the component tree based on
              the groupByMenuVisible state, versus the implementation below where the components
              stay in the component tree no matter what, and they're just hidden/visible based on
              the groupByMenuVisible state.
           */}
          <ChevronUp
            className={`ml-2 w-3 fill-slate-400 flex items-center ${
              groupByMenuVisible ? 'visible' : 'hidden'
            }`}
          />
          <ChevronDown
            className={`ml-2 w-3 fill-slate-400 flex items-center ${
              groupByMenuVisible ? 'hidden' : 'visible'
            }`}
          />
        </div>
        {groupByMenuVisible && (
          <div className="flex flex-col bg-white absolute border border-solid rounded shadow mt-1 p-2 min-w-[180px] z-20 border-slate-200">
            {Object.values(GQLActionStatisticsGroupByColumns).map((option) =>
              groupByMenuButton(option),
            )}
          </div>
        )}
      </div>
    </div>
  );
}
