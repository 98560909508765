import Markdown from 'react-markdown';

import Authors from '../authors';
import type { BlogPostConfig } from './types';

const markdown = `At Cove, our job is to help customers ensure the safety of content on their platforms, so naturally we need access to that content. Every day, we receive and analyze 100 million pieces of content for our customers. Storing and indexing this content enables customers to perform deep investigations, examine trends in user behavior, and provide full transparency to their users about all moderation decisions. As Trust & Safety practitioners, we believe privacy and security are absolutely paramount, and we recognize the immense trust our customers put in us to safeguard their users’ data.

To that end, Cove's recent achievement of SOC 2 Type I certification marks a significant milestone in our commitment to data security and customer trust. This certification, developed by the American Institute of CPAs (AICPA), evaluates the design and effectiveness of a company's controls around security, availability, processing integrity, confidentiality, and privacy.

The SOC 2 Type I certification process is rigorous, involving an extensive audit by an independent third party. Cove's successful certification demonstrates its robust security measures and risk management procedures, ensuring that customer data is managed with the utmost care and protection. These protections are particularly important in today's digital landscape, where data breaches and cyber threats continue to rise in number and severity.

Cove's SOC 2 Type I certification is more than just a compliance milestone; it's a testament to our ongoing commitment to security, trust, and customer satisfaction. It signals that Cove is a reliable partner, capable of handling sensitive information with the highest standards of care. This achievement reinforces Cove's position as a leader in the next generation of Trust & Safety software, dedicated to upholding the highest standards of data protection and operational integrity.

Looking ahead, we’ve already started on our Type II certification, so stay tuned!`;

const Soc2BlogPost = {
  slug: 'soc2',
  title: 'Cove Achieves SOC 2 Type I Certification',
  date: new Date('2024-02-24T00:00:00-07:00'),
  author: Authors['mason'],
  coverPhoto: {
    url: '/graphics/soc2-large.png',
    attribution: 'Image by upklyak on Freepik',
  },
  content: <Markdown>{markdown}</Markdown>,
} satisfies BlogPostConfig;

export default Soc2BlogPost;
