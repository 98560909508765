import {
  BookOpenAltFilled,
  FiltersliderFilled,
  GraphAltFilled,
  TapFilled,
} from '@/icons';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import DashboardHeader from '../components/DashboardHeader';
import TabBar from '../components/TabBar';

import PolicyScoresTab from './PolicyScoresTab';
import StrikeAnalyticsTab from './StrikeAnalyticsTab';
import StrikeEnabledActionsTab from './StrikeEnabledActionsTab';
import ThresholdsTab from './ThresholdsAndSettingsTab';

const UserStrikesDashboardTabs = [
  'policyScores',
  'strikeEnabledActions',
  'thresholdsAndSettings',
  'strikeAnalytics',
] as const;
type UserStrikesDashboardTab = (typeof UserStrikesDashboardTabs)[number];

export default function UserStrikesDashboard() {
  const dashboardTabsToComponents = {
    policyScores: <PolicyScoresTab />,
    strikeEnabledActions: <StrikeEnabledActionsTab />,
    thresholdsAndSettings: <ThresholdsTab />,
    strikeAnalytics: <StrikeAnalyticsTab />,
  };
  const [activeTab, setActiveTab] =
    useState<UserStrikesDashboardTab>('policyScores');
  const labelForTab = (tab: UserStrikesDashboardTab) => {
    switch (tab) {
      case 'policyScores':
        return 'Policy Scores';
      case 'strikeEnabledActions':
        return 'Strike Enabled Actions';
      case 'thresholdsAndSettings':
        return 'Thresholds & Settings';
      case 'strikeAnalytics':
        return 'Analytics';
    }
  };
  const iconForTab = (tab: UserStrikesDashboardTab) => {
    switch (tab) {
      case 'policyScores':
        return <BookOpenAltFilled width="22px" />;
      case 'strikeEnabledActions':
        return <TapFilled width="22px" />;
      case 'thresholdsAndSettings':
        return <FiltersliderFilled width="22px" />;
      case 'strikeAnalytics':
        return <GraphAltFilled width="22px" />;
    }
  };
  return (
    <div>
      <Helmet>
        <title>User Strikes</title>
      </Helmet>
      <DashboardHeader
        title="User Strikes"
        subtitle="Configure User Strikes through policies and actions.
          Cove automatically keeps count of User Strikes, and can trigger actions when strike thresholds are crossed.
          You can also use User Strike as a Signal in Rules to create additional triggers and actions."
      />
      <TabBar<UserStrikesDashboardTab>
        tabs={UserStrikesDashboardTabs.map((tab) => {
          return {
            label: labelForTab(tab),
            icon: iconForTab(tab),
            value: tab,
          };
        })}
        initialSelectedTab={activeTab ?? 'strikeEnabledActions'}
        currentSelectedTab={activeTab}
        onTabClick={(val) => {
          setActiveTab(val);
        }}
      />
      {dashboardTabsToComponents[activeTab]}
    </div>
  );
}
