import { sortBy } from 'lodash';

import { FormattedDate } from '../components/FormattedDate';
import { Typography } from '../components/ui/Typography';

import { BlogPosts } from './BlogPost';

export default function Blog() {
  return (
    <div className="container py-56 mx-auto">
      <Typography variant="display" align="center">
        Latest updates from Cove
      </Typography>

      <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-[repeat(auto-fill,minmax(400px,1fr))]">
        {sortBy(BlogPosts, 'date')
          .filter((post) => post.date <= new Date())
          .reverse()
          .map(({ slug, title, coverPhoto, date }, i) => (
            <a
              key={i}
              href={`/blog/${slug}`}
              className="flex flex-col max-w-lg cursor-pointer text-inherit decoration-inherit"
            >
              <img
                alt={title}
                className="mb-5 h-[210px] w-full rounded-md object-center object-cover"
                src={coverPhoto.url}
              />
              <FormattedDate date={date} className="mb-2" />
              <Typography variant="title" size="lg">
                {title}
              </Typography>
            </a>
          ))}
      </div>
    </div>
  );
}
