import {
  GQLUserPenaltySeverity,
  useGQLPoliciesQuery,
  useGQLUpdatePolicyMutation,
} from '@/graphql/generated';
import { ChevronDown, ChevronUp, Pencil, TrashCanFilled } from '@/icons';
import { Tree, treeFromList, TreeNode } from '@/utils/tree';
import { Input, Switch } from 'antd';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CoveModal from '../components/CoveModal';
import Table from '../components/table/Table';
import FullScreenLoading from '@/components/common/FullScreenLoading';

export type Policy = {
  id: string;
  name: string;
  policyText?: string;
  enforcementGuidelines?: string;
  userStrikeCount: number;
  penalty: GQLUserPenaltySeverity;
  applyUserStrikeCountConfigToChildren: boolean;
};

export default function PolicyScoresTab() {
  const {
    loading,
    error,
    data,
    refetch: refetchAllPolicies,
  } = useGQLPoliciesQuery({ fetchPolicy: 'network-only' });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [updatePolicy] = useGQLUpdatePolicyMutation({
    onCompleted: async () => {
      // Because of the cascading policy updates (i.e. parent policies affect
      // the children), we should refetch the whole policy tree when one is
      // updated
      refetchAllPolicies();
    },
    onError: () => {
      setErrorMessage('Error saving policy. Please try again.');
    },
  });

  const [policyTree, setPolicyTree] = useState<Tree<Policy>>(
    new Tree<Policy>('root', {
      id: '-1',
      name: 'root',
      penalty: GQLUserPenaltySeverity.None,
      userStrikeCount: 0,
      applyUserStrikeCountConfigToChildren: false,
    }),
  );
  const [expandedPolicies, setExpandedPolicies] = useState<string[]>([]);
  const [editingPolicices, setEditingPolicies] = useState<string[]>([]);

  const policyList = data?.myOrg?.policies;
  // We keep a map of policy IDs to the strike settings updated in the UI in
  // order to be able to send updates without opening a modal each time you
  // click edit policy
  const [updatedPolicyScores, setUpdatedPolicyScores] = useState<
    Record<string, Policy>
  >({});

  useEffect(() => {
    if (policyList) {
      setPolicyTree(
        treeFromList<Policy>(
          [...policyList].sort((a, b) => a.name.localeCompare(b.name)),
          {
            id: '-1',
            name: 'root',
            userStrikeCount: 0,
            penalty: 'NONE',
            applyUserStrikeCountConfigToChildren: false,
          },
          (policy) => ({
            id: policy.id,
            name: policy.name,
            userStrikeCount: policy.userStrikeCount,
            policyText: policy.policyText,
            enforcementGuidelines: policy.enforcementGuidelines,
            penalty: policy.penalty,
            applyUserStrikeCountConfigToChildren:
              policy.applyUserStrikeCountConfigToChildren,
          }),
        ),
      );
    }
  }, [policyList]);

  const renderPolicy = useCallback(
    (policy: TreeNode<Policy>, disabled: boolean) => {
      const toggleExpanded = (policyName: string) => {
        if (expandedPolicies.includes(policyName)) {
          setExpandedPolicies(
            expandedPolicies.filter((it) => it !== policyName),
          );
        } else {
          setExpandedPolicies([...expandedPolicies, policyName]);
        }
      };
      const toggleEditing = (policyId: string) => {
        if (editingPolicices.includes(policyId)) {
          setEditingPolicies(editingPolicices.filter((it) => it !== policyId));
        } else {
          setEditingPolicies([...editingPolicices, policyId]);
        }
      };

      // flatten children while adding a property to denote their indentation
      // level
      function flattenPolicies(policy: TreeNode<Policy>, level = 0) {
        // Initialize the result array with the current policy
        let result = [
          {
            ..._.omit(policy, 'children'),
            level,
            hasChildren: policy.children.length > 0,
          },
        ];

        // Recursively flatten each child policy and append to result array
        if (policy.children && policy.children.length > 0) {
          for (const child of policy.children) {
            result = result.concat(flattenPolicies(child, level + 1));
          }
        }
        return result;
      }

      const flattenedChildPolicies = flattenPolicies(policy);
      // we want to remove the root node from the flattened list
      flattenedChildPolicies.shift();
      const childPolicyIds = flattenedChildPolicies.map((p) => p.value.id);

      const discardChanges = (policyId: string) => {
        const filteredScores = _.omit(updatedPolicyScores, [
          policyId,
          ...childPolicyIds,
        ]);
        setUpdatedPolicyScores({
          ...filteredScores,
        });
      };

      const savePolicyScores = async (policyId: string) => {
        // only update this policy and it's child policies, not the whole list
        // of policies
        await Promise.all(
          [policyId, ...childPolicyIds].map(async (key) => {
            if (updatedPolicyScores[key]) {
              return updatePolicy({
                variables: {
                  input: {
                    ...updatedPolicyScores[key],
                  },
                },
              });
            }
          }),
        );
      };

      // don't allow editing child policies when this is set to true
      const editingChildrenDisabled =
        policy.value.applyUserStrikeCountConfigToChildren;

      const children = policy.children.length ? (
        <div className="flex items-stretch h-full pb-8">
          <div className="flex w-px h-full mx-2 bg-slate-200" />
          <div className="flex flex-col w-full mb-6">
            <ChildPoliciesTable
              policies={flattenedChildPolicies}
              editingDisabled={
                editingChildrenDisabled ||
                !editingPolicices.includes(policy.value.id)
              }
              updatedPolicyScores={updatedPolicyScores}
              setUpdatedPolicyScores={setUpdatedPolicyScores}
            />
          </div>
        </div>
      ) : null;

      const isTopLevel = !policy.parent || policy.parent.value.id === '-1';
      return (
        <div
          key={`${policy.key}-${policy.value.userStrikeCount}`}
          className="flex flex-col w-full"
        >
          <div className="flex flex-col items-stretch mb-6">
            <div
              className={`flex flex-col p-4 rounded-md border border-solid w-full bg-white border-slate-200`}
            >
              <div className="flex items-start justify-between">
                <div className="flex flex-col w-full">
                  <div className="flex items-center justify-between gap-6">
                    <div className="text-base font-bold text-start">
                      {policy.value?.name}
                    </div>
                    <div className="grow" />
                    {disabled ? null : editingPolicices.includes(
                        policy.value.id,
                      ) ? (
                      <div className="flex flex-row">
                        <div
                          className="flex flex-row cursor-pointer"
                          onClick={async () => {
                            discardChanges(policy.value.id);
                            if (expandedPolicies.includes(policy.value.name)) {
                              toggleExpanded(policy.value.name);
                            }
                            toggleEditing(policy.value.id);
                          }}
                        >
                          <TrashCanFilled
                            height={18}
                            width={18}
                            className="text-xs text-cove-red fill-cove-red"
                          />
                          <div className="pl-2 font-medium text-cove-red">
                            Discard Changes
                          </div>
                        </div>
                        <div
                          className="flex flex-row cursor-pointer pl-6"
                          onClick={async () => {
                            await savePolicyScores(policy.value.id);
                            if (expandedPolicies.includes(policy.value.name)) {
                              toggleExpanded(policy.value.name);
                            }
                            toggleEditing(policy.value.id);
                          }}
                        >
                          <Pencil
                            height={18}
                            width={18}
                            className="text-xs fill-cove-green"
                          />
                          <div className="pl-2 font-medium text-cove-green">
                            Save Policy Scores
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex flex-row cursor-pointer"
                        onClick={() => {
                          if (!expandedPolicies.includes(policy.value.name)) {
                            toggleExpanded(policy.value.name);
                          }
                          toggleEditing(policy.value.id);
                        }}
                      >
                        <Pencil
                          height={18}
                          width={18}
                          className="text-xs text-cove-purple fill-cove-purple"
                        />
                        <div className="pl-2 font-medium text-cove-purple">
                          Edit Policy Scores
                        </div>
                      </div>
                    )}
                  </div>
                  {isTopLevel ? (
                    <div className="flex items-center gap-2 text-slate-400">
                      Top-level Policy
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-row items-start mt-4 text-slate-700 text-start">
                <div className="flex flex-col gap-2 ">
                  <div className="text-sm">User Strike Score</div>
                  <Input
                    disabled={!editingPolicices.includes(policy.value.id)}
                    style={{ width: '20%' }}
                    maxLength={2}
                    value={
                      updatedPolicyScores[policy.value.id]?.userStrikeCount
                    }
                    placeholder="1"
                    defaultValue={policy.value.userStrikeCount}
                    onChange={(value) => {
                      if (!isNaN(parseInt(value.target.value))) {
                        setUpdatedPolicyScores({
                          ...updatedPolicyScores,
                          [policy.value.id]: {
                            ...policy.value,
                            ...updatedPolicyScores[policy.value.id],
                            userStrikeCount: parseInt(value.target.value, 10),
                          },
                        });
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2 ">
                  {policy.children.length > 0 ? (
                    <div>
                      <div className="text-sm">Apply to sub-policies</div>
                      <div className="mt-1">
                        <Switch
                          disabled={!editingPolicices.includes(policy.value.id)}
                          className="w-5"
                          size="small"
                          defaultChecked={
                            policy.value.applyUserStrikeCountConfigToChildren
                          }
                          onChange={(value) => {
                            setUpdatedPolicyScores({
                              ...updatedPolicyScores,
                              [policy.value.id]: {
                                ...policy.value,
                                ...updatedPolicyScores[policy.value.id],
                                applyUserStrikeCountConfigToChildren: value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {policy.children.length ? (
                <div className="flex items-center gap-4 mb-2 pt-3 font-medium text-slate-500">
                  <div>
                    {policy.children.length === 1
                      ? '1 SUB-POLICY'
                      : `${policy.children.length} SUB-POLICIES`}
                  </div>
                  <div className="w-1 h-1 rounded-full bg-slate-500"></div>
                  <div
                    className="cursor-pointer flex items-center justify-start gap-1.5 whitespace-nowrap"
                    onClick={() => toggleExpanded(policy.value.name)}
                  >
                    {expandedPolicies.includes(policy.value.name)
                      ? 'Hide all'
                      : 'Show all'}
                    {expandedPolicies.includes(policy.value.name) ? (
                      <ChevronUp className="flex text-xs" />
                    ) : (
                      <ChevronDown className="flex text-xs" />
                    )}
                  </div>
                </div>
              ) : null}
              {expandedPolicies.includes(policy.value.name) ? children : null}
            </div>
          </div>
        </div>
      );
    },
    [editingPolicices, expandedPolicies, updatedPolicyScores, updatePolicy],
  );
  const errorModal = (
    <CoveModal
      title="Error"
      visible={errorMessage != null}
      onClose={() => setErrorMessage(undefined)}
      footer={[
        {
          title: 'OK',
          onClick: () => setErrorMessage(undefined),
        },
      ]}
    >
      {errorMessage}
    </CoveModal>
  );

  const policies = useMemo(() => {
    return (
      <div className="flex flex-col items-stretch w-full mb-6">
        {policyTree?.root.children.map((p) => {
          return renderPolicy(p, false);
        })}
      </div>
    );
  }, [policyTree, renderPolicy]);

  if (error) {
    throw error;
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-stretch w-full mt-6">
        <div className="flex flex-col items-start w-full overflow-y-auto">
          {policies}
        </div>
      </div>
      {errorModal}
    </div>
  );
}

function ChildPoliciesTable(props: {
  policies: { value: Policy; level: number; hasChildren: boolean }[];
  editingDisabled: boolean;
  updatedPolicyScores: Record<string, Policy>;
  setUpdatedPolicyScores: (
    value: React.SetStateAction<Record<string, Policy>>,
  ) => void;
}) {
  const {
    policies,
    editingDisabled,
    updatedPolicyScores,
    setUpdatedPolicyScores,
  } = props;
  const columns = useMemo(
    () => [
      {
        Header: 'Sub-Policy',
        accessor: 'name',
        canSort: false,
      },
      {
        Header: 'User Strike Score',
        accessor: 'userStrikeCount', // accessor is the "key" in the data
        canSort: false,
      },
      {
        Header: 'Apply to sub-policies',
        accessor: 'applyUserStrikeCountConfigToChildren', // accessor is the "key" in the data
        canSort: false,
      },
    ],
    [],
  );
  const tableData = useMemo(() => {
    return policies?.slice().map((policy) => {
      return {
        name: (
          <div className="flex flex-row">
            {policy.level > 1 ? (
              <div className={`pr-${policy.level * 4}`}>{'>'}</div>
            ) : null}
            <div>{policy.value.name}</div>
          </div>
        ),
        userStrikeCount: (
          <Input
            disabled={editingDisabled}
            style={{ width: '20%' }}
            maxLength={2}
            value={updatedPolicyScores[policy.value.id]?.userStrikeCount}
            placeholder="1"
            defaultValue={policy.value.userStrikeCount}
            onChange={(value) => {
              if (!isNaN(parseInt(value.target.value))) {
                setUpdatedPolicyScores({
                  ...updatedPolicyScores,
                  [policy.value.id]: {
                    ...policy.value,
                    ...updatedPolicyScores[policy.value.id],
                    userStrikeCount: parseInt(value.target.value, 10),
                  },
                });
              }
            }}
          />
        ),
        applyUserStrikeCountConfigToChildren: policy.hasChildren ? (
          <div className="mt-1">
            <Switch
              disabled={editingDisabled}
              className="w-5"
              size="small"
              defaultChecked={policy.value.applyUserStrikeCountConfigToChildren}
              onChange={(value) => {
                setUpdatedPolicyScores({
                  ...updatedPolicyScores,
                  [policy.value.id]: {
                    ...policy.value,
                    ...updatedPolicyScores[policy.value.id],
                    applyUserStrikeCountConfigToChildren: value,
                  },
                });
              }}
            />
          </div>
        ) : null,
      };
    });
  }, [editingDisabled, updatedPolicyScores, policies, setUpdatedPolicyScores]);
  return <Table columns={columns} data={tableData} disableFilter={true} />;
}
