import { useState } from 'react';
import Markdown from 'react-markdown';

import RequestDemoModal from '../../components/RequestDemoModal';

import Authors from '../authors';
import type { BlogPostConfig } from './types';

const Content = () => {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);

  return (
    <div className="flex flex-col">
      <>
        <Markdown
          components={{
            // p: ({ children }) => <p className="pb-0 !mt-0 !mb-0">{children}</p>,
            ul: ({ children }) => (
              <ul className="list-none px-10 !mt-0">{children}</ul>
            ),
            // eslint-disable-next-line jsx-a11y/alt-text
            // img: ({ src }) => <img className="!p-0 !m-0" src={src} />,
          }}
        >
          {`Content policies are the backbone of online communities, shaping behavior and interactions to ensure safe and engaging online spaces. These policies are meticulously crafted, embodying the platform’s values, user expectations, and legal obligations. Large platforms invest significant resources into developing nuanced and sophisticated policies to incentivize positive behavior and discourage toxicity. However, the enforcement of these policies remains a significant challenge - policies are often written documents with nuanced language, and it’s hard to encode that nuanced language into machines that can adequately understand it. It’s especially difficult to enforce these policies well when most of the available solutions in the market are generic, one-size-fits-all solutions that aren’t tailored to a particular platform’s policies.

In the first part of this two part series, we’ll walk through why content policies matter, and why they need to be carefully tailored to each platform.

#### The Importance of Content Policies

Content policies are not merely sets of rules, but rather foundational elements that define the platform's identity and operational ethos. These policies are the result of extensive research, user feedback, legal consultations, and company values. They are designed to address specific challenges and opportunities unique to the platform.

**Why Content Policies matter**
- **User Trust and Safety:** Policies are put in place to ensure that users feel safe and respected, fostering trust and encouraging positive engagement. When users know that a platform actively enforces rules against harassment, hate speech, and other harmful behaviors, they are more likely to participate and contribute positively to the community.
- **Community Norms:** Policies reflect the community’s norms and values, ensuring that interactions align with the platform’s goals. By setting clear expectations for behavior, platforms can cultivate a positive environment that aligns with their brand and mission.
- **Preventing Abuse and Fraud:** Effective policies can deter malicious activities such as scams, phishing, and fraud. For example, an e-commerce platform with robust anti-fraud policies can protect both buyers and sellers, enhancing the platform’s reputation for reliability and safety, and safeguarding users from financial risk.
- **Promoting Fairness and Inclusivity:** Policies that address issues such as discrimination, harassment, and bias contribute to creating an inclusive environment where all users feel valued and respected. This can help attract a diverse user base and foster a more vibrant and dynamic community.
- **Legal Compliance:** Well-crafted policies help platforms comply with legal requirements, avoiding penalties and legal disputes. In an era of increasing regulatory scrutiny, having clear, enforceable policies is crucial for avoiding fines and maintaining a good standing with regulators and law enforcement.
- **Protecting Intellectual Property:** For platforms that host user-generated content, policies that safeguard intellectual property rights are essential. These policies prevent the unauthorized use of copyrighted material, supporting content creators and maintaining the platform’s integrity.
- **Enhancing User Experience:** Clear and well-enforced policies improve the overall user experience by reducing exposure to harmful or disruptive content. This leads to higher user satisfaction and retention rates, which are critical for the platform’s growth and success.

#### Why Platform-Specific?

Each platform operclient/public/graphics/platform-policies.pngates within a unique context, catering to different user bases, types of content, and interactions. The nuances in their policies are crucial for effectively managing their specific environments. Generic, broad-brush policies fail to capture the intricacies of individual platforms, leading to enforcement issues and user dissatisfaction.
`}
        </Markdown>
        <img
          src="/graphics/platform-policies.png"
          alt="Platform Policies"
          className="w-2/3"
        />
        For instance:
        <Markdown>
          {`- A resale marketplace needs policies that prevent the sale of counterfeit items and ensure respectful interactions. A handmade goods marketplace needs the same policies, but it also needs to protect the integrity of artisanal work and ensure that items being sold are truly handmade. This marketplace would require a custom **Handmade Goods Only** policy that wouldn’t make sense for the resale marketplace.
- A dating app, like most other platforms, requires robust policies against harassment and abuse. But it also requires policies against behaviors that are far more prevalent on dating apps, such as catfishing, sextortion, and the sharing of non-consensual intimate imagery (NCII).
- An ed-tech platform that allows students to collaborate on group projects requires nuanced policies around the sharing of sexual content. As a platform targeted at students - many of whom are minors - they will likely have a strict, no-tolerance policy for most sexual content. However, they’ll have to make room for students to discuss legitimate academic subjects, such as reproductive biology, anatomy, classic art with sexual depictions, and sexual health. This requires a detailed, sophisticated policy that clearly delineates the topics that should be allowed.
- A review site like Yelp must have clear guidelines to prevent fraudulent reviews and maintain the credibility of its content. Fake or plagiarized reviews, scams posted by competitors, and personal attacks against business owners will all appear on these sites in unique ways that look very different from the types of scams and hate in the other corners of the internet.

In short, every platform is unique, and that uniqueness demands customizing your policies to fit the needs of your platform. In part two of this series, we’ll explore some of the challenges around enforcing platform-specific policies, and we’ll walk through how Cove can help you overcome those challenges.

Want to learn more about how Cove can help you enforce platform-specific policies?`}
        </Markdown>
        <span
          onClick={showRequestDemoModal}
          className="cursor-pointer text-primary"
        >
          Schedule a demo today
        </span>
      </>
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="BLOG_POST_PLATFORM_POLICIES_PT_1"
      />
    </div>
  );
};

const PlatformPoliciesPt1BlogPost = {
  slug: 'platform-policies-pt-1',
  title: 'Part 1: Why Platform-Specific Policies Matter',
  date: new Date('2024-08-20T00:00:00-07:00'),
  author: Authors['nishtha'],
  coverPhoto: {
    url: '/graphics/platform-policies-cover.png',
    attribution: 'Image by upklyak on Freepik',
  },
  content: <Content />,
} satisfies BlogPostConfig;

export default PlatformPoliciesPt1BlogPost;
